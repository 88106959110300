<template>
  <!-- 角色管理 -->
  <div>
    <div class="nav">
      <div>
        <!-- 查询 -->
        <div>
          <div class="search_box">
            <div class="search_main" style=" margin-left: 34px">
              <div class="status">
                <div class="text">下单时间</div>
                <div>
                  <el-date-picker
                    v-model="datatime"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  >
                  </el-date-picker>
                </div>
              </div>
            </div>
            <div class="search_main" style=" margin-left: 32px">
              <div class="status">
                <div class="text">订单状态</div>
                <div>
                  <el-select v-model="queryInfo.orderStatus">
                    <el-option value="" label="全部"></el-option>
                    <el-option
                      v-for="(item, index) in orderStatus"
                      :key="index"
                      :label="item"
                      :value="index"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <div class="search_main" style=" margin-left: 32px">
              <div class="status">
                <div class="text">用户账号</div>
                <div>
                  <el-input
                    style="width: 260px"
                    placeholder="请输入内容"
                    v-model="mobile"
                  >
                  </el-input>
                </div>
              </div>
            </div>

            <div class="search_main">
              <div class="status">
                <div class="text">订单号</div>
                <div>
                  <el-input
                    placeholder="请输入内容"
                    v-model="queryInfo.combineOrderNo"
                    style="width: 260px"
                  ></el-input>
                </div>
              </div>
            </div>
            <br />
            <div class="search_main">
              <div class="status">
                <div class="text" style="width: 160px; margin-left: -70px">
                  收货人手机号
                </div>
                <div>
                  <el-input
                    v-model="queryInfo.receiverPhone"
                    placeholder="请输入内容"
                    style="width: 260px"
                  ></el-input>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status">
                <div class="text" style="width: 160px; margin-left: -70px">
                  团长姓名
                </div>
                <div>
                  <el-input
                    v-model="brokerRealName"
                    placeholder="请输入内容"
                    style="width: 260px"
                  ></el-input>
                </div>
              </div>
            </div>
            <div class="search_main">
              <div class="status">
                <div class="text" style="width: 160px; margin-left: -70px">
                  团长手机号
                </div>
                <div>
                  <el-input
                    v-model="brokerMobile"
                    placeholder="请输入内容"
                    style="width: 260px"
                  ></el-input>
                </div>
              </div>
            </div>
            <div class="search_main" >
              <div class="status1" style="display: flex">
                <div class="demandBtn" @click="search">
                  <img src="../../../assets/images/search.png" alt="" /> 查询
                </div>
                <div class="resetBtn" @click="reset">
                  <img src="../../../assets/images/reset.png" alt="" />重置
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 查询 -->
      </div>
    </div>
    <div style="height: 20px"></div>
    <div class="body" style="height: 100%">
      <!-- 列表 -->
      <div class="dataTable">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%; font-family: PingFangRoutine"
          :header-cell-style="tableHeaderColor"
          :cell-style="{ color: '#2C2C2C', fontSize: '14px' }"
        >
        <el-table-column prop="brokerData.realName" label="团长姓名" align="center">
          </el-table-column>
          <el-table-column prop="brokerData.mobile" label="团长手机号" align="center">
          </el-table-column>
          <el-table-column prop="combineOrderNo" label="订单号" align="center" width="200">
          </el-table-column>
          <el-table-column prop="memberData.mobile" label="用户账号" align="center">
          </el-table-column>
          <el-table-column prop="receiverName" label="收货人" align="center">
          </el-table-column>
          <el-table-column
            prop="receiverPhone"
            label="收货人手机号"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="totalAmountStr"
            label="订单金额"
            align="center"
            width="120"
          >
            <template #default="scope"
              >￥{{ scope.row.totalAmountStr }}</template
            >
          </el-table-column>
          <el-table-column
            prop="payAmountStr"
            label="实付金额"
            align="center"
            width="120"
          >
            <template #default="scope">￥{{ scope.row.payAmountStr }}</template>
          </el-table-column>
          <el-table-column prop="createTime" label="下单时间" align="center" width="200">
          </el-table-column>
          <el-table-column prop="payType" label="支付方式" align="center">
            <template #default="scope">
              <div v-if="scope.row.payType == 0">{{ "未支付" }}</div>
              <div v-if="scope.row.payType == 1">{{ "白条" }}</div>
              <div v-if="scope.row.payType == 2">{{ "支付宝" }}</div>
              <div v-if="scope.row.payType == 3">{{ "微信" }}</div>
              <div v-if="scope.row.payType == 4">{{ "农行" }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="forbidden"
            label="订单状态"
            align="orderStatus"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.orderStatus == 0">待支付</span>
              <span v-if="scope.row.orderStatus == 1">已取消</span>
              <span v-if="scope.row.orderStatus == 2">已支付</span>
              <span v-if="scope.row.orderStatus == 3">已完成</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="260" align="center">
            <template #default="scope">
              <div style="display: flex; justify-content: center">
                <div
                  style="width: 40px; height: 30px"
                  class="operabtn"
                  @click="orderdeta(scope.row.id, scope.row.orderStatus)"
                >
                  <img src="../../../assets/images/xiangqing.png" alt="" />
                </div>

                <el-popover
                  ref="popover"
                  placement="bottom"
                  :width="175"
                  trigger="hover"
                  title="商品"
                >
                    <div
                      style="width: 40px"
                      class="xinxibtn"
                      @mouseover="selectStyle(scope.row.id)"
                      slot="reference"
                    >
                      <img src="../../../assets/images/xinxi.png" alt="" />
                    </div>
                  <div
                    v-for="(item, index) in goodssketchs"
                    :key="index"
                    class="goodssketch"
                  >
                    {{ item }}
                  </div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 列表 -->
    </div>
    <div style="height: 10px"></div>
    <div class="footer">
      <!-- 分页 -->
      <div class="Pagination">
        <div class="Pagination_amin">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="1"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <div class="Pagination_btn" style="height: 28px">确定</div>
      </div>
      <!-- 分页 -->
    </div>
  </div>
</template>
<script>
import {
  orderPayment
} from "@/api/order";
import { orderList,orderFloat,orderStatus } from '@/api/group'
import "../../../font-style/font.css";
export default {
  data() {
    return {
      datatime: [], //日期
      goodssketchs: [],
      orderStatus: ["待支付", "已取消", "已支付", "已完成"],
      payment: [],
      mobile:'',
      queryInfo: {
        startDate: "",
        endDate: "",
        createTime: "",
        orderStatus: "",
        combineOrderNo: "",
        receiverPhone: "",
        currPage: "",
        "memberData.mobile" : "",
        "brokerData.realName":"",
        "brokerData.mobile":""
      },
      brokerRealName:'',
      brokerMobile:'',
      tableData: [],
      total: null,
    };
  },

  created() {
    console.log(this.queryInfo)
    this.list();
  },

  methods: {
    fn(status) {
      return this.orderStatus[status];
    },
    //订单列表
    list() {
      this.queryInfo["memberData.mobile"]= this.mobile
      this.queryInfo["brokerData.realName"]= this.brokerRealName
      this.queryInfo["brokerData.mobile"]= this.brokerMobile
      orderList(this.queryInfo).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total * 1;
      });
      //订单状态
      orderStatus().then((res) => {
        this.orderStatus = res.data;
      });
      //支付方式
      orderPayment().then((res) => {
        this.payment = res.data;
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.list();
    },
    // table
    tableHeaderColor({ rowIndex }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #333333;;fontSize: '14px'";
      }
    },
    //搜索
    search() {
      this.queryInfo.startTime = this.datatime[0];
      this.queryInfo.endTime = this.datatime[1];
      this.queryInfo.currPage = ""
      this.total = 0
      this.list();
    },
    // 重置
    reset() {
      this.total=0
      this.datatime = [];
      this.queryInfo = {}
      this.mobile = ''
      this.brokerRealName = ''
      this.brokerMobile = ''
      this.list();
    },
    //详情页
    orderdeta(id, orderStatus) {
      this.$router.push({
        path: "/group/order/getById",
        query: {
          id: id,
          orderStatus: orderStatus,
        },
      });
    },

    // 商品简述
    selectStyle(id) {
      orderFloat({
        id: id,
      }).then((res) => {
        this.goodssketchs = [];
        if(res.data){
          res.data.forEach((item, index) => {
          if (index <= 4) this.goodssketchs.push(item);
        });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/css/common.less";

/deep/ .el-table__cell.is-center {
  text-align: left;
}

/deep/ .cell {
  text-align: center;
}

#el-popover-2419 {
  width: 220px !important;
  z-index: 9999;
}

.operabtn {
  width: 60px;
  height: 30px;
  background: #279dfb;
  border-radius: 8px;
  text-align: center;
  line-height: 30px;
  margin-right: 0;

  img {
    margin-top: 7px;
    width: 18px;
    height: 16px;
  }
}

.xinxibtn {
  width: 60px;
  height: 30px;
  background: #fc9714;
  border-radius: 8px;
  text-align: center;
  line-height: 30px;
  margin-left: 10px;

  img {
    margin-top: 7px;
    width: 18px;
    height: 16px;
  }
}

.delete {
  width: 60px;
  height: 30px;
  background: #fb6a57;
  border-radius: 8px;
  text-align: center;
  line-height: 30px;
  margin-left: 10px;

  i {
    font-size: 18px;
    color: white;
    margin-top: 6px;
    width: 18px;
    height: 16px;
  }
}

.quxiaobtn {
  width: 60px;
  height: 30px;
  background: #ffcc33;
  border-radius: 8px;
  text-align: center;
  line-height: 30px;
  margin-left: 10px;

  img {
    margin-top: 7px;
    width: 18px;
    height: 16px;
  }
}

.querenbtn {
  width: 60px;
  height: 30px;
  background: #18bea4;
  border-radius: 8px;
  text-align: center;
  line-height: 30px;
  margin-left: 10px;

  img {
    margin-top: 7px;
    width: 18px;
    height: 16px;
  }
}

.status > .text:nth-child(1) {
  width: 57px;
  text-align: right;
}

.status > .text:nth-child(2) {
  margin-left: 19px;
}
</style>
